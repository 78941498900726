import React, { createContext, useContext } from 'react';
import URI from 'urijs';
import getConfig from 'next/config';

import useLocalStorage from '../common/useLocalStorage';
import { setUserCookie } from '../common/utils';

enum RequestMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

// const PRODUCTION_API =
//     'https://7xfxegn5d3.execute-api.ap-southeast-1.amazonaws.com/production';
// const STAGING_API =
//     'https://1jlps90kg1.execute-api.ap-southeast-1.amazonaws.com/dev';

export interface ApiClientContextParams {
    invokePostRequest: (path: string, params: any) => Promise<any>;
    invokePostWithFormDataRequest: (path: string, params: any) => Promise<any>;
    invokePutRequest: (path: string, params: any) => Promise<any>;
    invokeGetRequest: (path: string, params: any) => Promise<any>;
    invokeDeleteRequest: (path: string, params: any) => Promise<any>;
    setAuthToken: React.Dispatch<string>;
    authToken: string;
}

export const ApiClientDefault = {
    invokePostRequest: () => Promise.resolve(null),
    invokePostWithFormDataRequest: () => Promise.resolve(null),
    invokePutRequest: () => Promise.resolve(null),
    invokeGetRequest: () => Promise.resolve({}),
    invokeDeleteRequest: () => Promise.resolve(null),
    setAuthToken: () => '',
    authToken: '',
};

export const ApiClientContext =
    createContext<ApiClientContextParams>(ApiClientDefault);

const BASE_URL = `/api`;
type Parameters = { [key: string]: any };

const WITH_BODY_OPTION = ['POST', 'PUT', 'DELETE'];

const getBodyOption = (
    method: RequestMethods,
    contentType: string,
    requestParams: any,
) => {
    if (contentType !== 'multipart/form-data') {
        return WITH_BODY_OPTION.includes(method)
            ? { body: JSON.stringify(requestParams) }
            : {};
    }

    const formData = new FormData();

    const requestParamsKeys = Object.keys(requestParams);
    requestParamsKeys.map((key) => {
        formData.append(key, requestParams[key]);
    });

    return { body: formData };
};

const METHODS_WITH_URL_PARAMS = ['GET', 'DELETE'];

export default function ApiClientProvider({
    children,
}: {
    children: Array<React.ReactNode> | React.ReactNode;
}) {
    const [authToken, setAuthToken] = useLocalStorage('authToken', '');

    const handleUnauthorizedRequest = () => {
        setAuthToken('');
        setUserCookie({
            authToken: '',
            id: '',
            role: '',
        });
    };

    const invokeNetworkRequest = async ({
        method,
        path,
        parameters,
        contentType = 'application/json',
        deleteRequestBody = {},
    }: {
        method: RequestMethods;
        path: string;
        parameters?: Parameters;
        contentType?: string;
        deleteRequestBody?: { [key: string]: any };
    }) => {
        const isUrlWithParams = METHODS_WITH_URL_PARAMS.includes(method);

        const baseURLWithPath = `${BASE_URL}${path}`;
        const url =
            isUrlWithParams && parameters
                ? URI(baseURLWithPath).query(parameters).toString()
                : `${BASE_URL}${path}`;

        const contentTypeHeader: any =
            contentType === 'multipart/form-data'
                ? {}
                : { 'Content-Type': contentType };

        const result = await fetch(url, {
            method,
            headers: {
                ...contentTypeHeader,
                authorization: authToken,
            },
            ...getBodyOption(method, contentType, {
                ...(method === RequestMethods.DELETE
                    ? deleteRequestBody
                    : parameters),
            }),
        });

        return result;
    };

    const invokePutRequest = async (path: string, parameters: any = {}) => {
        const response = await invokeNetworkRequest({
            path: path,
            method: RequestMethods.PUT,
            parameters,
        });

        if (response.status === 403 || response.status === 401) {
            handleUnauthorizedRequest();
            return {};
        }

        const results = await response.json();

        return results;
    };

    const invokePostRequest = async (path: string, parameters: any = {}) => {
        const response = await invokeNetworkRequest({
            path: path,
            method: RequestMethods.POST,
            parameters,
        });

        if (response.status === 403 || response.status === 401) {
            handleUnauthorizedRequest();
            return {};
        }

        const results = await response.json();

        return results;
    };

    const invokePostWithFormDataRequest = async (
        path: string,
        parameters: any = {},
    ) => {
        const response = await invokeNetworkRequest({
            path: path,
            method: RequestMethods.POST,
            parameters,
            contentType: 'multipart/form-data',
        });

        if (response.status === 403 || response.status === 401) {
            handleUnauthorizedRequest();
            return {};
        }

        const results = await response.json();

        return results;
    };

    const invokeGetRequest = async (path = '', parameters = {}) => {
        const response = await invokeNetworkRequest({
            path: path,
            method: RequestMethods.GET,
            parameters,
        });

        if (response.status === 403 || response.status === 401) {
            handleUnauthorizedRequest();
            return {};
        }

        const results = await response.json();

        return results;
    };

    const invokeDeleteRequest = async (path = '', parameters = {}) => {
        const response = await invokeNetworkRequest({
            path: path,
            method: RequestMethods.DELETE,
            parameters,
        });

        if (response.status === 403 || response.status === 401) {
            handleUnauthorizedRequest();
            return {};
        }

        const results = await response.json();

        return results;
    };

    const providerValues = {
        invokePostRequest,
        invokePostWithFormDataRequest,
        invokePutRequest,
        invokeGetRequest,
        invokeDeleteRequest,
        setAuthToken,
        authToken,
    };

    return (
        <ApiClientContext.Provider value={providerValues}>
            {children}
        </ApiClientContext.Provider>
    );
}

export const useApiClient = () => useContext(ApiClientContext);
