import React, { Dispatch } from 'react';

export const LOCAL_STORAGE_KEY = 'JNU_OTS_KEYS';

export const getAccessKey = (key: string): string => {
    return `${LOCAL_STORAGE_KEY}-${key}`;
};

export const fetchLocalStorageData = <T>(
    accessKey: string,
    initialValue: T,
): T => {
    if (typeof window === 'undefined') {
        return initialValue;
    }
    // eslint-disable-next-line no-undef
    const valueString = window?.localStorage?.getItem(accessKey);

    if (!valueString) {
        return initialValue;
    }
    return Object.freeze(JSON.parse(valueString));
};

export default function useLocalStorage<T>(
    key: string,
    initialValue: T,
): [T, Dispatch<T>] {
    const accessKey = getAccessKey(key);
    const [value, setValue] = React.useState(() =>
        fetchLocalStorageData(accessKey, initialValue),
    );

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line no-undef
            window.localStorage.setItem(accessKey, JSON.stringify(value));
        }
    }, [value, accessKey]);

    return [value, setValue];
}
